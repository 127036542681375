<template lang="html">
  <div class="navigation-container">
    <NavigationHeader :year="year" @back="back">
      <IxRes v-if="isOverview">dlgCert.headers.{{ pillar }}</IxRes>
      <IxRes v-else>dlgCert.headers.{{ pillar }}_{{ $route.name }}</IxRes>

      <template v-if="isOverview" #help>
        <PillarOverviewHelp />
      </template>
    </NavigationHeader>

    <router-view />
  </div>
</template>

<script>
import NavigationHeader from './widgets/NavigationHeader'
import PillarOverviewHelp from './widgets/PillarOverviewHelp'

export default {
  components: {
    NavigationHeader,
    PillarOverviewHelp
  },
  props: {
    pillar: {
      type: String,
      required: true
    },
    year: {
      type: Number,
      required: true
    }
  },
  computed: {
    isOverview () {
      return this.$route.name === this.pillar
    }
  },
  methods: {
    back () {
      if (this.isOverview) {
        this.$router.push({name: 'dashboard'})
      } else {
        this.$router.push({name: this.pillar})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-container {
  display: flex;
  flex-direction: column;
}
</style>
