<template lang="html">
  <div class="nav-header">
    <IxButton large back @click="$emit('back')">
      <IxRes>dlgCert.navigation.buttons.back</IxRes>
    </IxButton>

    <div class="header-content">
      <div class="header-text">
        <slot />
      </div>

      <HelpPrompt v-if="$slots.help">
        <slot name="help" />
      </HelpPrompt>
    </div>

    <div class="harvest-year">
      <IxRes :context="{year: year}">
        dlgCert.navigation.harvestYear
      </IxRes>
    </div>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

import HelpPrompt from './HelpPrompt'

export default {
  components: {
    HelpPrompt,
    IxButton
  },
  props: {
    year: Number
  }
}
</script>

<style lang="scss" scoped>
.nav-header {
  display: flex;
  margin-bottom: 2em;
  align-items: center;
}

.header-content {
  display: flex;
  margin: 0 1em;
  justify-content: flex-start;
  align-items: baseline;
}

.header-text, .harvest-year {
  font-size: 2em;
  text-transform: uppercase;
}

.header-text {
  margin-right: 1em;
}

.harvest-year {
  margin-left: auto;
}
</style>
